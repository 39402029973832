import * as React from "react";
import { Card, createStyles, withStiboStyles, WithStiboStyles } from "tim-ui";
import { SecondLevelGroup } from "./SecondLevelGroup";
import { ThirdLevelGroup } from "./ThirdLevelGroup";
import { FlatGroup } from "./FlatGroup";
import { ElementNames, generateElementId } from "../../common/dataUtils";
import { AttributeGroup } from "../screen/ProductDetailsScreen/Product/types";

const styles = stiboTheme =>
  createStyles({
    content: {
      "&:last-child": {
        paddingBottom: 6 * stiboTheme.layout.gridPoint
      }
    },
    card: {
      boxShadow: "none",
      borderRadius: "4px"
    },
    nestedCard: {
      boxShadow: "none"
    },
    tileHeading: {
      textTransform: "uppercase",
      color: stiboTheme.palette.black[400],
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif",
      letterSpacing: "1.5px",
      fontSize: "10px"
    },
    lev1Heading: {
      color: stiboTheme.palette.black[400],
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif",
      fontSize: "20px",
      fontWeight: 500
    },
    flatGroupContainer: {
      padding: "16px 24px 16px 24px"
    }
  });

export type attributeGroupCardClasses = "content" | "card" | "nestedCard" | "tileHeading" | "lev1Heading" | "overlineHeading" | "flatGroupContainer";

export interface Props {
  title: string;
  attributeGroups: AttributeGroup;
  testKey: string | number;
}

const AttributeGroupCard = (props: Props & WithStiboStyles<attributeGroupCardClasses>) => {
  const { classes, attributeGroups, title } = props;
  const elementId: string = generateElementId(ElementNames.attributeGroup, title);


  const attributeGroupRenderer = {
    // todo add type
    flat: (
      <div className={classes && classes.flatGroupContainer}>
        <FlatGroup classes={classes} group={attributeGroups} />
      </div>
    ),
    twoLevels: <SecondLevelGroup classes={classes} attributeGroup={attributeGroups} />,
    threeLevels: <ThirdLevelGroup classes={classes} attributeGroup={attributeGroups} />
  };

  return (
    <div data-name="item" id={elementId}>
      <Card className={classes && classes.card} data-name="content">
        {attributeGroupRenderer[attributeGroups.displayType]}
      </Card>
    </div>
  );
};

export default withStiboStyles(styles)(AttributeGroupCard);
