import * as React from "react";
import { Typography } from "tim-ui";
import { StepParameter, WithTranslation } from "tim-bridge";
import { nsTranslate } from "../../../../i18n";

interface EmptyProductMessageProps {
  selection?: StepParameter<string>;
}

interface EmptyProductMessageInnerProps extends EmptyProductMessageProps, WithTranslation { }

const EmptyProductMessage = (props: EmptyProductMessageInnerProps) => (
  <div style={{ height: "100vh" }}>
    <Typography variant="headline" test-id="notFoundProductMsg">
      {props.t("emptyProduct.message", { defaultValue: "Failed to fetch details for product with id:" })} "{props.selection && props.selection.stepId}".
    </Typography>
  </div>
);

export default (nsTranslate)(EmptyProductMessage);
