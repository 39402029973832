import { createStyles } from "tim-ui";
import { mediaQueries } from "../../common/mediaQueries";
import { inherits } from "util";

const gutter = 5;

export type TClasses = typeof styles;

export const styles = () => {
  return createStyles({
    masonryPanel: {
      padding: `${gutter}px`,
      [mediaQueries.columns4]: {
        width: `calc(25% - ${2 * gutter}px)`
      },
      [mediaQueries.columns3]: {
        width: `calc(33.333% - ${2 * gutter}px)`
      },
      [mediaQueries.columns2]: {
        width: `calc(50% - ${2 * gutter}px)`
      },
      [mediaQueries.columns1]: {
        width: `calc(100% - ${2 * gutter}px)`
      }
    }
  });
};
