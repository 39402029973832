import * as React from "react";
import {createStyles, StyleRules, WithStiboStyles, withStiboStyles} from "tim-ui";

const styles = (stiboTheme): StyleRules<ParentNodesListItemClassName> => {
    return createStyles({
        parentnode: {
            display: "block",
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "normal",
            color: stiboTheme.palette.navigation[500],
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
                textUnderlineColor: stiboTheme.palette.navigation[500]
            },
        }
    });
};
export type ParentNodesListItemClassName = "parentnode";

interface ParentNodesListItemProps {
    index: number;
    title: string;
    onNodeClick(nodeIndex: number, nodeTitle: string);
}

class ParentNodesListItem extends React.Component<ParentNodesListItemProps & WithStiboStyles<ParentNodesListItemClassName>> {

    constructor(props: ParentNodesListItemProps & WithStiboStyles<ParentNodesListItemClassName>) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        const {onNodeClick} = this.props;
        onNodeClick(this.props.index, this.props.title);
    }

    render() {
        const {classes} = this.props;
        return (
            <li className={classes.parentnode} key={this.props.index} onClick={this.handleOnClick}>{this.props.title}</li>
        );
    }
}

export default withStiboStyles(styles)(ParentNodesListItem);