import * as React from "react";

import { ProductNotFoundMessage, EmptyProductMessage } from "../Errors";

type ProductErrorsProps = {
  stepId: string;
  nodeType: string;
  productTitle?: string;
  isProductNotFound: boolean;
  isEmptyProduct: boolean;
  children: JSX.Element;
  onEditModeOpen?: () => void;
};

export default class ProductErrors extends React.Component<ProductErrorsProps> {
  render() {
    const { stepId, nodeType, productTitle } = this.props;

    if (this.props.isProductNotFound) {
      return (
        <ProductNotFoundMessage
          selection={{
            stepId,
            __typename: nodeType
          }}
        />
      );
    }

    if (this.props.isEmptyProduct && productTitle) {
      return <EmptyProductMessage onEditModeOpen={this.props.onEditModeOpen} productTitle={productTitle} />;
    }

    return this.props.children;
  }
}
