import * as React from "react";

import MasonryLayout from "../../../masonryLayout/MasonryLayout";
import { memberRenderer } from "../Sections/Members";
import { Members } from "../Sections/types";

interface WithoutSectionsProps {
  members: Array<Members>;
}

export default class WithoutSections extends React.Component<WithoutSectionsProps> {
  render() {
    const { members } = this.props;
    return (
      <div style={{ padding: "0px 32px 0px 32px" }}>
        {" "}
        <MasonryLayout>
          {members.map((member, index) => {
            return (memberRenderer[member.type] as any)({
              member,
              index
            });
          })}
        </MasonryLayout>
      </div>
    );
  }
}
