import * as React from "react";
import {useEffect, useState} from "react";
import {BridgeCallbacks} from "./App";
import {IGraphQLClient} from "./graphql/client";
import {Node, GetNodeResponse, Reason} from "./graphql/types";
import ProductSummary from "./ProductSummary";
import {createStyles, StyleRules, WithStiboStyles, withStiboStyles} from "tim-ui";

export type NodeSummaryClass = "container";

const styles = (stiboTheme): StyleRules<NodeSummaryClass> =>
    createStyles({
        container: {
            clear: "both",
            fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
            fontSize: "14px",
            backgroundColor: "#FFFFFF",
            color: stiboTheme.palette.black[200],
            padding: "0 32px",
            display: "flex",
            justifyContent: "space-between"
        }
    });

interface AppContainerProps {
    graphQLClient: IGraphQLClient;
    nodeId: string | undefined;
    nodeType: string | undefined;
    bridgeCallbacks: BridgeCallbacks;
    hasSufficiencyLicence: boolean;
    thumbnailURL?: string;
    canNavigatePath: boolean;
}

const Container = (props: AppContainerProps & WithStiboStyles<NodeSummaryClass>) => {
    const [node, setNode] = useState();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    useEffect(() => {
            if (!bridgeCallbacks.showAlert || !bridgeCallbacks.setIsRecalculatingHandler || !bridgeCallbacks.setReloadHandler || !bridgeCallbacks.isBeingRecalculated) {
                console.log("Missing bridge callbacks for Product Summary. Component won't load.");
                return;
            }

            !!nodeId &&
            bridgeCallbacks.isBeingRecalculated(nodeId, nodeType, (isNodeUpdating: boolean) => {
                setIsUpdating(isNodeUpdating);
            });
            // TODO: The following checks will most likely revert the effect of this in cases when user navigates away from the product and back before the recalc is done

            if (!isUpdating) {
                bridgeCallbacks.setIsRecalculatingHandler(() => {
                    setIsUpdating(true);
                });
            }

            if (isUpdating) {
                bridgeCallbacks.setReloadHandler(() => {
                    setIsUpdating(false);
                });
            }
            if (!isUpdating) {
                nodeId &&
                graphQLClient
                    .getNode(nodeId, nodeType)
                    .then((value: GetNodeResponse) => {
                        if (!value.loading) {
                            setNode(new Node(value.data.node));
                        }
                    })
                    .catch((reason: Reason) => {
                        bridgeCallbacks ? bridgeCallbacks.showAlert("error", "Failed to load node id: " + nodeId, reason.message) : console.error(reason);
                    });
            }
        }, [isUpdating]
    );
    const {graphQLClient, nodeId, nodeType, bridgeCallbacks, hasSufficiencyLicence, thumbnailURL, canNavigatePath, classes} = props;
    console.log("SUMMARY STANDALONE has sufficiency licence: ", hasSufficiencyLicence);
    return (
        <>
            {node && (
                <ProductSummary
                    title={node.title}
                    id={node.stepId}
                    path={node.path}
                    approvalState={node.approvalState}
                    approvalDate={node.approvalDate}
                    currentRevision={node.currentRevision}
                    lastUpdateDate={node.lastUpdateDate}
                    className={classes.container}
                    isUpdating={isUpdating}
                    hasSufficiencyLicence={hasSufficiencyLicence}
                    thumbnailURL={thumbnailURL}
                    onThumbnailClick={bridgeCallbacks.showPopup}
                    canNavigatePath={canNavigatePath}
                    onNodeClick={bridgeCallbacks.onNodeClick}
                    onPathClick={bridgeCallbacks.showAlert}
                />
            )}
        </>
    );
};

export const AppContainer = withStiboStyles(styles)(Container);
