import * as React from "react";
import { WithStiboStyles, withStiboStyles } from "tim-ui";

import { styles, TClasses } from "./UngroupedSection.styles";

class UngroupedSection extends React.Component<WithStiboStyles<TClasses>> {
  render() {
    const { classes } = this.props;
    return <section className={classes.container}>{this.props.children}</section>;
  }
}

export default withStiboStyles(styles)(UngroupedSection);
