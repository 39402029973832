import React = require("react");
import { WithStiboStyles } from "tim-ui";

import AttributesTableCard from "./AttributesTableCard";
import { attributeGroupCardClasses } from "./AttributeGroupCard";
import { ElementNames, generateElementId } from "../../common/dataUtils";
import { AttributeGroup } from "../screen/ProductDetailsScreen/Product/types";

const FlatGroup = (props: { group: AttributeGroup } & WithStiboStyles<attributeGroupCardClasses>): JSX.Element => {
  const { group, classes } = props;
  const { stepId, title } = group;
  const elementId: string = generateElementId(ElementNames.attributeGroup, title);

  return (
    <div id={elementId} className={"flat group"}>
      <AttributesTableCard key={stepId} title={title} titleStyle={classes.lev1Heading} attributes={group.attributes} />
    </div>
  );
};

export { FlatGroup };
