import * as React from "react";
import {createStyles, StyleRules, Tooltip, WithStiboStyles, withStiboStyles} from "tim-ui";

const styles = (stiboTheme): StyleRules<PathNodeItemBaseClassName> => {
    return createStyles({
        divcontainer: {
            display: "flex",
            marginTop: "10px",
        },
        node: {
            marginTop: "3px",
            maxWidth: "240px",
            fontSize: "12px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textOverflow: "ellipsis",
            color: stiboTheme.palette.navigation[500],
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
                textUnderlineColor: stiboTheme.palette.navigation[500]
            }
        },
        pathdivider: {
            fontSize: "18px",
            margin: "0px 8px 0px 8px",
            color: "rgba(0, 0, 0, 0.26)",
        },
    });
};

export type PathNodeItemBaseClassName = "divcontainer" | "pathdivider" | "node";

interface PathNodeItemProps {
    id: number;
    title: string;
    index: number;
    showDivider: boolean;
    onClick(index: number, title: string);
}

interface PathNodeState {
    hasClicked: boolean;
}

class PathNodeItem extends React.Component<PathNodeItemProps & WithStiboStyles<PathNodeItemBaseClassName>, PathNodeState> {
    constructor(props: PathNodeItemProps & WithStiboStyles<PathNodeItemBaseClassName>) {
        super(props);
        this.state = {hasClicked : false};
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        const {onClick} = this.props;
        this.setState({hasClicked : true});
        onClick(this.props.id, this.props.title);
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.divcontainer}>
                <Tooltip title={this.props.title} placement="top">
                <div key={this.props.index} className={classes.node} style = {this.state.hasClicked ? {fontWeight: "bold"} : {fontWeight: "normal"}}
                     onClick={this.handleOnClick}>{this.props.title}</div>
                </Tooltip>
                {this.props.showDivider && <div className={classes.pathdivider}> ▸ </div>}
            </div>
        );
    }
}

export default withStiboStyles(styles)(PathNodeItem);