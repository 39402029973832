import { AttributeGroupTO, StepAttribute } from "./types";

export class ShowInWorkbench {
  public fixAttributeHierarchy = (attribute: StepAttribute, attributeGroupsMap: Map<string, AttributeGroupTO | undefined>): StepAttribute | null => {
    const matchedAttributeGroups = attribute.attributeGroups.map((ag) => {
      return attributeGroupsMap.get(ag.stepId);
    });
    const attributeGroups = matchedAttributeGroups
      .map(groupTO => {
        if (groupTO && groupTO.showInWorkbench) {
          const { parent } = groupTO;
          if (parent) {
            return parent.showInWorkbench
              ? { ...groupTO, parent: this.fixPredecessors(parent) }
              : { ...groupTO, parent: this.fixPredecessors(this.replaceParentToFirstCorrectPredecessor(parent)) };
          }
        }

        return undefined;
      })
      .filter(g => g !== undefined) as AttributeGroupTO[];

    return attributeGroups.length > 0
      ? {
        ...attribute,
        attributeGroups
      }
      : null;
  };

  private replaceParentToFirstCorrectPredecessor = (attributeGroupTO: AttributeGroupTO): AttributeGroupTO | null => {
    const { parent } = attributeGroupTO;

    if (parent) {
      if (parent.showInWorkbench) {
        return { ...parent };
      } else {
        return this.replaceParentToFirstCorrectPredecessor(parent);
      }
    }

    return null;
  };

  private fixPredecessors = (attributeGroup: AttributeGroupTO | null): AttributeGroupTO | null => {
    if (attributeGroup) {
      const { parent } = attributeGroup;

      if (parent) {
        return parent.showInWorkbench
          ? { ...attributeGroup, parent: this.fixPredecessors(parent) }
          : { ...attributeGroup, parent: this.fixPredecessors(this.replaceParentToFirstCorrectPredecessor(parent)) };
      }
    }

    return attributeGroup;
  };
}
