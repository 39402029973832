import {createStyles, StyleRules, withStiboStyles, WithStiboStyles} from "tim-ui";
import * as React from "react";
import ParentNodesListItem from "./ParentNodesListItem";

const styles = (stiboTheme): StyleRules<PopupContentClassName> => {
    return createStyles({
        popup: {
            zIndex: 3000,
            padding: "8px 16px",
            borderRadius: "4px",
            overflow: "visible",
            position: "fixed",
            marginTop: "50px",
            visibility: "visible",
            backgroundColor: "white",
            border: "1px solid #bdbdbd",
            boxShadow: "0px 0px 20px #999",
            "&:before": {
                content: `""`,
                top: "-19px",
                position: "absolute",
                borderBottom: "10px solid white",
                border: "0.8em solid transparent",
            },
        }
    });
};
export type PopupContentClassName = "popup";

interface PopupContentProps {
    parentNodes: string[];
    leftPosition: number;
    onNodeClick(nodeIndex: number, nodeTitle: string);
}

class PopupContent extends React.Component<PopupContentProps & WithStiboStyles<PopupContentClassName>> {
    private fixedLeftPosition = 10;

    constructor(props: PopupContentProps & WithStiboStyles<PopupContentClassName>) {
        super(props);
        this.onNodeClick = this.onNodeClick.bind(this);
    }

    onNodeClick(nodeIndex, nodeTitle) {
        const {onNodeClick} = this.props;
        onNodeClick(nodeIndex, nodeTitle);
    }

    render() {
        const { classes, parentNodes, leftPosition } = this.props as PopupContentProps & WithStiboStyles<PopupContentClassName>;
        let listOfParentNodes = parentNodes.map((parentNode) => {
            return (<ParentNodesListItem title={parentNode[1]} index={parseInt(parentNode[0], 10)}
                                 onNodeClick={this.onNodeClick}/>);
        });
        return (
            <ul style = {{left: leftPosition + this.fixedLeftPosition}} className={classes.popup}>{listOfParentNodes}</ul>
        );
    }
}

export default withStiboStyles(styles)(PopupContent);