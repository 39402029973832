import * as React from "react";

import UngroupedSection from "./UngroupedSection";
import MasonryLayout from "../../../masonryLayout/MasonryLayout";
import Section from "./Section";
import { ISection, IUngroupedSection } from "./types";
import { memberRenderer, attributeGroupMemberRenderer } from "./Members";

interface SectionsProps {
  ungroupedSection?: IUngroupedSection;
  sections: ISection[];
}

const Sections = (props: SectionsProps) => {
  const { sections, ungroupedSection } = props;
  const sectionNames = Object.keys(sections);

  return (
    <React.Fragment>
      {sectionNames.map((name, index) => {
        const section: ISection = sections[name];

        const members = section.members;
        const membersContent = members.map((member, index) => memberRenderer[member.type]({ member, index }));
        const sectionContent = section.noMasonry ? membersContent : <MasonryLayout>{membersContent}</MasonryLayout>;

        return (
          <Section name={section.name} key={index}>
            {sectionContent}
          </Section>
        );
      })}
      {ungroupedSection && ungroupedSection.members.length > 0 ? (
        <UngroupedSection>
          <MasonryLayout>
            {ungroupedSection.members.map((member, index) =>
              attributeGroupMemberRenderer({
                member,
                index
              })
            )}
          </MasonryLayout>
        </UngroupedSection>
      ) : null}
    </React.Fragment>
  );
};

export { Sections };
