import { StandardProps } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "tim-bridge";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";

export type SufficiencyLoadingClasses = "container" | "icon";

const styles = (stiboTheme): StyleRules<SufficiencyLoadingClasses> =>
  createStyles({
    container: {
      position: "relative",
      padding: "0 16px 0 16px",
      color: stiboTheme.palette.black[300],
      textAlign: "center",
      lineHeight: "32px",
      height: "32px",
      borderRadius: "4px",
      cursor: "progress",
      overflow: "hidden",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: stiboTheme.palette.grey[200]
      }
    },
    icon: {
      marginRight: "16px",
      "& div": {
        display: "inline-block",
        width: "8px",
        height: "8px",
        borderRadius: "2px"
      },
      "& div:nth-child(1)": {
        backgroundColor: stiboTheme.palette.warning[700],
        verticalAlign: "bottom",
        marginBottom: "6px",
        marginRight: "-2px"
      },
      "& div:nth-child(2)": {
        backgroundColor: stiboTheme.palette.success[500],
        verticalAlign: "top",
        marginTop: "6px"
      },
      "& div:nth-child(3)": {
        backgroundColor: stiboTheme.palette.danger[500],
        verticalAlign: "bottom",
        marginBottom: "6px",
        marginLeft: "-2px"
      }
    }
  });

export interface SufficiencyLoadingProps extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyLoadingClasses> {
  dataQa?: string;
}

const SufficiencyLoading: React.FC<SufficiencyLoadingProps & WithStiboStyles<SufficiencyLoadingClasses>> = props => {
  const { dataQa, className: classNameProp, classes, ...rest } = props;

  const { t } = useTranslation();

  const className = classNames(classes.container, classNameProp);

  return (
    <React.Fragment>
      <span className={className} data-qa={dataQa || "sufficiency-overview-loading"} {...rest}>
        <span className={classes.icon}>
          <div />
          <div />
          <div />
        </span>
        {`${t("sufficiencyLoading.icon", {
          defaultValue: "Calculating Scores..."
        })}`}
      </span>
    </React.Fragment>
  );
};

export default withStiboStyles(styles)(SufficiencyLoading);
