import * as React from "react";
import { nsTranslate } from "../../i18n";
import { WithTranslation } from "tim-bridge";

interface NoPicturesPlaceholderProps {
  className?: string;
}

interface NoPicturesPlaceholderInnerProps extends NoPicturesPlaceholderProps, WithTranslation { }

const NoPicturesPlaceholder = (props: NoPicturesPlaceholderInnerProps) => (
  <div key={"noPicturesPlaceholder"} className={props.className}>
    {props.t("noPictures.message", { defaultValue: "No image available" })}
  </div>
);

export default (nsTranslate)(NoPicturesPlaceholder);
