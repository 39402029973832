import gql from "graphql-tag";

export const attributesFromGroupsFragment = gql`
  fragment Attributes on Product {
    parent {
      title
    }
    path
    values(attributeGroupStepIds: $attributeGroups) {
      edges {
        node {
          attribute {
            stepId
            title
          }
          simpleValue
        }
      }
    }
  }
`;

export const collectionEdges = gql`
  fragment Edges on ValueConnection {
    edges {
      node {
        attribute {
          displaySequence {
            simpleValue
          }
          attributeGroups {
            ...AttributeGroup
          }
          stepId
          title
          isConditionallyValid: isConditionallyValidForProduct(stepId: $stepId)
        }
        simpleValue
        ... on SingleValue {
          value
          valueStepId
        }
        ... on MultiValue {
          values {
            value
            valueStepId
          }
        }
      }
    }
  }
`;
