export const MULTIVALUE_SEPARATOR_STEP = "<multisep/>";
export const MULTIVALUE_SEPARATOR = " " + String.fromCharCode(183) + " ";

export const isLongValue = (value: string): boolean => {
  return value.length > 60;
};

export const isMultiValue = (value: string): boolean => {
  return value.includes(MULTIVALUE_SEPARATOR);
};

export const parseMultiValuedAttribute = (value: string): string => {
  if (value === undefined || value === null) {
    return "";
  } else {
    return value.replace(new RegExp(MULTIVALUE_SEPARATOR_STEP, "g"), MULTIVALUE_SEPARATOR);
  }
};
