import gql from "graphql-tag";

export const attributeGroup = gql`
  fragment AttributeGroup on AttributeGroup {
    stepId
  }
`;

export const getAttributeGroupFieldsFragment = attributeGroupSection => {
  return `
  fragment AttributeGroupFields on AttributeGroup {
    stepId,
    title,
    showInWorkbench,
    displaySequence {
      simpleValue
    }
    attributeGroupSection: value(attributeStepId: "${attributeGroupSection}" ) {
      ... on SingleValue {
        simpleValue
        valueStepId
      }
    }
  }
`;
};

