import { groupBy, orderBy } from "lodash";
import { AttributeGroup, Image, UNGROUPED_ATTRIBUTE_SECTION } from "../Product/types";
import { SectionStore } from "./SectionStore";
import { SectionStoreBase } from "./SectionStoreBase";
import { AttributesGroupBySections, CarouselMember, ISection, IUngroupedSection } from "./types";
import { UngroupedSectionStore } from "./UngroupedSectionStore";

export interface ISectionableProductStore {
  sortedSections: ISection[];
  ungroupedSection?: IUngroupedSection;
}

export interface SectionableProductStoreProps {
  attributeGroups: AttributeGroup[];
  primaryImage: Image | undefined;
  productImages: Image[];
}
export const sortSections = <T extends ISection>(sections: T[]): T[] => {
  return sections
    ? orderBy(
      sections,
      (section: T) => {
        return section.order;
      },
      "asc"
    )
    : [];
};

export class SectionableProductStore implements ISectionableProductStore {
  sortedSections: SectionStoreBase[];
  ungroupedSection: IUngroupedSection | undefined;

  constructor(props: SectionableProductStoreProps) {
    const { attributeGroups, primaryImage, productImages } = props;
    const attributesGroupedBySections = this.groupToSections(attributeGroups);
    const additionalSectionNames = this.createAdditionalSectionNames(attributesGroupedBySections); // keys without ungrouped section
    const sections = this.createSections(additionalSectionNames, attributesGroupedBySections);

    this.sortedSections = this.sortSections(sections as SectionStoreBase[]); // todo remove casting;
    this.ungroupedSection = this.createUngroupedSection(attributesGroupedBySections);

    this.extendSectionWithImages(this.sortedSections, primaryImage, productImages, 0);
  }

  private groupToSections(attributeGroups: AttributeGroup[]): AttributesGroupBySections {
    return groupBy(attributeGroups, (group: AttributeGroup) => {
      return group.section.label;
    });
  }

  private createUngroupedSection(attributesGroupedBySections: AttributesGroupBySections): IUngroupedSection | undefined {
    const ungrouped = attributesGroupedBySections[UNGROUPED_ATTRIBUTE_SECTION.label];

    return (
      ungrouped &&
      new UngroupedSectionStore({
        attributeGroups: ungrouped,
        name: UNGROUPED_ATTRIBUTE_SECTION.label
      })
    );
  }

  private extendSectionWithImages(sections: SectionStoreBase[], primaryImage: Image | undefined, productImages: Image[], index: number) {
    if (primaryImage) {
      productImages = productImages.filter(image => image.title !== primaryImage.title);
    }

    const allImages: Image[] = primaryImage ? [primaryImage, ...productImages] : [...productImages];

    const carouselMember = new CarouselMember(allImages);
    sections[index] && sections[index].addMemberAsAFirstOne(carouselMember);
  }

  private createSections(sectionNames: string[], attributesGroupedBySections: AttributesGroupBySections): ISection[] {
    return sectionNames
      .map(key => {
        const attributeGroups = attributesGroupedBySections[key];
        return attributeGroups
          ? new SectionStore({
            attributeGroups,
            name: key,
            order: attributeGroups[0].section.orderId
          })
          : null;
      })
      .filter(section => section) as SectionStore[];
  }

  private createAdditionalSectionNames(attributesGroupedBySections: AttributesGroupBySections): string[] {
    return Object.keys(attributesGroupedBySections).filter(key => key !== UNGROUPED_ATTRIBUTE_SECTION.label);
  }

  private sortSections(sections: ISection[]): SectionStoreBase[] {
    return (
      sections &&
      orderBy(
        sections,
        (section: ISection) => {
          return section.order;
        },
        "asc"
      )
    );
  }
}
