import * as React from "react";
import { nsTranslate } from "../../i18n";
import { WithTranslation } from "tim-bridge";

interface InvalidPicturePlaceholderProps {
  className?: string;
}

interface InvalidPicturePlaceholderInnerProps extends InvalidPicturePlaceholderProps, WithTranslation { }

const InvalidPicturePlaceholder = (props: InvalidPicturePlaceholderInnerProps) => (
  <div key={"invalidPicturePlaceholder"} className={props.className}>
    {props.t("invalidPicture.message", { defaultValue: "Invalid image type" })}
  </div>
);

export default (nsTranslate)(InvalidPicturePlaceholder);
