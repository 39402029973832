import * as React from "react";
import {
  withComponentParams,
  withBridgeCallbacks,
  WithBridgeCallbacks,
  TimGraphApollo,
  withSelection,
  WithSelection,
  ShowAlertSeverity,
  ShowAlertCallback,
  WithComponentParams,
  withGraphClient
} from "tim-bridge";
import { ThemeProvider } from "tim-ui";
import { hot } from "react-hot-loader";
import { ClientOptions, GraphQLClient, IGraphQLClient } from "./graphql/client";
import { AppContainer } from "./AppContainer";

class App extends React.Component<
  Partial<{ contextId: string; workspaceId: string }> &
    WithComponentParams<{ hasSufficiencyLicence: boolean; thumbnailURL?: string; canNavigatePath: boolean }> &
    Partial<WithBridgeCallbacks> &
    WithSelection & { client: TimGraphApollo }
, any> {
  graphQLClient: IGraphQLClient;
  nodeId: string | undefined;
  nodeType: string | undefined;
  bridgeCallbacks: BridgeCallbacks;

  constructor(
    props: Partial<{ contextId: string; workspaceId: string }> &
      WithComponentParams<{ hasSufficiencyLicence: boolean; thumbnailURL?: string, canNavigatePath: boolean }> &
      Partial<WithBridgeCallbacks> &
      WithSelection & { client: TimGraphApollo }
  ) {
    super(props);
    const { contextId, workspaceId, client, bridgeCallbacks, selection } = this.props;
    this.nodeId = selection ? selection.stepId : undefined;
    this.nodeType = selection ? selection.__typename : undefined;

    const clientOptions: ClientOptions = {
      contextID: contextId ? contextId : "Context1",
      workspaceID: workspaceId ? workspaceId : "Main"
    };
    this.graphQLClient = new GraphQLClient(client, clientOptions);

    this.bridgeCallbacks = bridgeCallbacks
      ? bridgeCallbacks
      : {
          showAlert: (severity: ShowAlertSeverity, headline: string, message: string) => console.log(severity, headline, message),
          setIsRecalculatingHandler: (action: () => void) => action(),
          setReloadHandler: (action: () => void) => action(),
          onNodeClick: (nodeIndex: number, nodeTitle: string) => {},
        };
  }

  render() {
    const hasSufficiencyLicence =
      this.props.componentParams && this.props.componentParams.hasSufficiencyLicence ? this.props.componentParams.hasSufficiencyLicence : false;
    const thumbnailURL = this.props.componentParams && this.props.componentParams.thumbnailURL;
    const canNavigatePath = this.props.componentParams.canNavigatePath;
    return (
      <ThemeProvider>
        <AppContainer
          graphQLClient={this.graphQLClient}
          nodeId={this.nodeId}
          nodeType={this.nodeType}
          bridgeCallbacks={this.bridgeCallbacks}
          hasSufficiencyLicence={hasSufficiencyLicence}
          thumbnailURL={thumbnailURL}
          canNavigatePath={canNavigatePath}
        />
      </ThemeProvider>
    );
  }
}
const AppWithSelection = withSelection(withGraphClient()(App as any));
const AppWithBridgeCallbacks = withBridgeCallbacks(AppWithSelection);
export const AppWithComponentParams = withComponentParams(AppWithBridgeCallbacks);
export const AppWithHotReload = hot(module)(AppWithComponentParams);
export default AppWithComponentParams;

export interface BridgeCallbacks {
  showAlert: ShowAlertCallback;
  setIsRecalculatingHandler?: (action: () => void) => void;
  setReloadHandler?: (action: () => void) => void;
  isBeingRecalculated?: (nodeId: string, nodeType: string | undefined, action: (isUpdating: boolean) => void) => boolean;
  showPopup?: () => void;
  onNodeClick?: (nodeIndex: number, nodeTitle: string) => void;
}
