import { RichTextFn, RichTextTags, RICH_TEXT_TAG_QUERY } from "@stibo/value-components";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { useStepQueryParams, useTimGraphClient, TimGraphApollo } from "tim-bridge";
import { DocumentNode } from "apollo-link";

export async function fetchTagDefinitions(client: TimGraphApollo, query: DocumentNode, contextStepId: string, workspaceStepId: string) {
  return await client!.query({
    query,
    variables: {
      contextStepId,
      workspaceStepId
    }
  });
}

export function useRichText(): RichTextFn {
  const queryParams = useStepQueryParams();
  const client = useTimGraphClient().getDefaultClient();

  const [tags, setTags] = useState<RichTextTags>({
    loadingTags: true,
    styleTags: [],
    characterTags: [],
    linkTags: [],
    footnoteTags: []
  });
  useEffect(() => {
    fetchTagDefinitions(
      client,
      gql`
        ${RICH_TEXT_TAG_QUERY}
      `,
      queryParams.contextStepId,
      queryParams.workspaceStepId
    )
      .then(result => {
        setTags({
          loadingTags: false,
          styleTags: result.data.allStyleTagDefinitions,
          characterTags: result.data.allCharacterTagDefinitions,
          linkTags: result.data.allHyperlinkTagDefinitions,
          footnoteTags: result.data.allFootnoteTagDefinitions
        });
      })
      .catch(error => {
        console.error(error);
        setTags({
          loadingTags: false,
          styleTags: [],
          characterTags: [],
          linkTags: [],
          footnoteTags: []
        });
      });
  }, []);

  return () => {
    return tags;
  };
}
