import * as React from "react";
import { Reducer, useReducer, useState } from "react";
import { Card, CardContent, createStyles, withStiboStyles, WithStyles } from "tim-ui";
import ProductImage from "../productImage/ProductImage";
import { Image } from "../screen/ProductDetailsScreen/Product/types";
import ImageViewerButton from "./ImageViewerButton";
import InvalidPicturePlaceholder from "./InvalidPicturePlaceholder";
import NoPicturesPlaceholder from "./NoPicturesPlaceholder";
import Thumbnail from "./Thumbnail";
import ImageViewerOverlay from "./ImageViewerOverlay";

export type CarouselBaseClassName =
  | "card"
  | "media"
  | "previewCaptions"
  | "preview"
  | "icon"
  | "iconButton"
  | "noPicturesPlaceholder"
  | "invalidPicturePlaceholder";

const styles = (stiboTheme: any) =>
  createStyles({
    card: {
      display: "flex",
      boxShadow: "none",
      marginTop: 0,
    },
    media: {
      backgroundSize: "contain"
    },
    preview: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    },
    previewCaptions: {
      marginLeft: "66px",
      marginRight: "66px",
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: "1em",
      fontWeight: "normal"
    },
    icon: {
      height: "1.4em",
      width: "1.4em"
    },
    noPicturesPlaceholder: {
      border: `1px solid ${"rgba(0, 0, 0, 0.12)"}`,
      borderRadius: "4px",
      padding: "4em 1.5em",
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "20px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: stiboTheme.palette.black[300],
      textAlign: "center"
    },
    invalidPicturePlaceholder: {
      border: `1px solid ${"rgba(0, 0, 0, 0.12)"}`,
      padding: "2em 1.5em",
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: stiboTheme.palette.black[300]
    }
  });

export interface CarouselProps {
  pictures: Image[];
}

const Carousel: React.FC<WithStyles<CarouselBaseClassName> & CarouselProps> = props => {
  const { classes, pictures } = props;

  const [state, dispatch] = useReducer(carouselReducer, {
    pictureNo: 0,
    pictureOk: true
  });

  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const setPictureHandler = index => {
    dispatch({ type: "PICTURE_NO", value: index });
  };

  const picturesLength = pictures.length;
  const picture = pictures[state.pictureNo];
  const pictureOk = state.pictureOk;

  let thumbnails;
  if (picturesLength > 1) {
    let thumbnailPictures = [...pictures].slice(0, 5);

    thumbnails = (
      <CardContent>
        <div className={classes.preview} id={`Carousel_thumbnails`}>
          {thumbnailPictures.map((picture, index) => (
            <Thumbnail
              isSelected={index === state.pictureNo}
              onSelected={setPictureHandler}
              key={index}
              url={picture.thumbnail}
              title={picture.title}
              index={index}
            />
          ))}
          {picturesLength > 5 && <ImageViewerButton key={5} picturesLength={picturesLength} setShowOverlay={setShowOverlay} />}
        </div>
      </CardContent>
    );
  }

  return (
    <>
      {picturesLength > 0 ? (
        <Card className={classes.card} data-name="content" id={`Carousel_Card`}>
          {thumbnails}
          {picture && pictureOk ? (
            <ProductImage image={picture} onClick={() => setShowOverlay(true)} hoverable  isFullScreenPreview={false}/>
          ) : (
              <InvalidPicturePlaceholder className={classes.invalidPicturePlaceholder} />
            )}
          {showOverlay && <ImageViewerOverlay pictures={pictures} state={state} dispatch={dispatch} setShowOverlay={setShowOverlay} />}
        </Card>
      ) : (
          <NoPicturesPlaceholder className={classes.noPicturesPlaceholder} />
        )}
    </>
  );
};

export interface CarouselState {
  pictureNo: number;
  pictureOk: boolean;
}

export type CarouselAction = { type: "PICTURE_NO"; value: number } | { type: "PICTURE_OK"; value: boolean };

export const carouselReducer: Reducer<CarouselState, CarouselAction> = (state, action) => {
  switch (action.type) {
    case "PICTURE_NO":
      return {
        ...state,
        pictureNo: action.value
      };
    case "PICTURE_OK":
      return {
        ...state,
        pictureOk: action.value
      };
    default:
      return state;
  }
};

export default withStiboStyles(styles)(Carousel);
