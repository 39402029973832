import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import {Check, createStyles, Error, IconButton, StyleRules, Warning, WithStiboStyles, withStiboStyles} from "tim-ui";
import {SeverityLevel, WithSeverityLevel} from "../../graphql/query/types";
import SufficiencyMessage from "./panel/SufficiencyMessage";
import {MessageHolders} from "../screen/ProductDetailsScreen/ProductSummary/graphql/types";

export type SufficiencyIndicatorClasses = "container" | "indicator" | "success" | "warning" | "error"  | "messageSection" | "successicon" | "warningicon" | "erroricon" | "svg";

const styles = (stiboTheme): StyleRules<SufficiencyIndicatorClasses> =>
  createStyles({
    container: {
      display: "inline-block",
      color: stiboTheme.palette.black[300],
      whiteSpace: "nowrap"
    },
    indicator: {
      color: stiboTheme.colors.white,
      padding: "2px 7px 2px 7px",
      borderRadius: "2px"
    },
    success: {
      backgroundColor: stiboTheme.palette.success[500]
    },
    warning: {
      backgroundColor: stiboTheme.palette.warning[700]
    },
    error: {
      backgroundColor: stiboTheme.palette.danger[500]
    },
    erroricon: {
      width: "20px",
      height: "20px",
      color: stiboTheme.palette.danger[500],
    },
    svg: {
      width: "18px",
      height: "18px",
    },
    successicon: {
      width: "20px",
      height: "20px",
      color: stiboTheme.palette.success[500],
    },
    warningicon: {
      width: "20px",
      height: "20px",
      color: stiboTheme.palette.warning[500],
    },
    messageSection: {
      marginTop: "8px"
    }
  });

interface SufficiencyIndicatorProps extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyIndicatorClasses> {
  severityLevel: SeverityLevel;
  title: string;
  score: string;
  showAsBoolean?: boolean;
  "data-qa"?: string;
  messageHolders?: MessageHolders[];
}

const SufficiencyIndicator: React.FC<SufficiencyIndicatorProps & WithStiboStyles<SufficiencyIndicatorClasses>> = props => {
  const { severityLevel, title, score, showAsBoolean, className: classNameProp, classes, "data-qa": dataQa, messageHolders, ...rest } = props;

  const className = classNames(classes.container, classNameProp);
  const configuration = classNames(
    classes.indicator,
    {
      [classes.success]: severityLevel === SeverityLevel.Success
    },
    {
      [classes.warning]: severityLevel === SeverityLevel.Warning
    },
    {
      [classes.error]: severityLevel === SeverityLevel.Error
    }
  );

  let sufficiencyScore = score;
  let sufficiencyIcon;
  let iconId;
  if (showAsBoolean) {
    switch (severityLevel) {
      case SeverityLevel.Success:
        sufficiencyIcon = <IconButton className={classes.successicon}><Check className={classes.svg}/></IconButton>;
        iconId = "Success";
        break;
      case SeverityLevel.Warning:
        sufficiencyIcon = <IconButton className={classes.warningicon}><Warning className={classes.svg}/></IconButton>;
        iconId = "Warning";
        break;
      case SeverityLevel.Error:
        sufficiencyIcon = <IconButton className={classes.erroricon}><Error className={classes.svg}/></IconButton>;
        iconId = "Error";
        break;
    }
  }
  let messageList: JSX.Element[] = [];
  if (messageHolders) {
    messageHolders.forEach((holder, outerIndex) => {
      holder.messages.forEach((info, innerIndex) => {
        const {severityLevel: messageSeverityLevel, message} = info;
        messageList.push(<SufficiencyMessage key={`message-${outerIndex}-${innerIndex}`}
                                             severityLevel={messageSeverityLevel} message={message}/>);
      });
    });
    messageList = messageList.sort((a, b) => compareSeverityLevel(a.props, b.props));
  }

  return (
      <span id={iconId}>
    <span className={className} data-qa={dataQa || "sufficiency-indicator"} {...rest}>
      {title} {showAsBoolean ? <span data-qa={iconId}>{sufficiencyIcon}</span> :
        <span className={configuration}>{sufficiencyScore}</span>}
    </span>
        {messageList.length > 0 && (
            <div className={classes.messageSection} data-qa="sufficiency-panel-messages">
              {messageList}
            </div>
        )}
  </span>
  );
};

export const compareSeverityLevel = (a: WithSeverityLevel, b: WithSeverityLevel) => {
  if (a.severityLevel === SeverityLevel.Error) return -1;
  if (b.severityLevel === SeverityLevel.Error) return 1;
  if (a.severityLevel === SeverityLevel.Warning) return -1;
  if (b.severityLevel === SeverityLevel.Warning) return 1;
  if (!a) return 1;
  if (!b) return -1;
  return 0;
};

export default withStiboStyles(styles)(SufficiencyIndicator);
