import * as React from "react";
import { createStyles, withStiboStyles, WithStyles } from "tim-ui";

export type ImageViewerBaseClassName = "buttonContainer";

export const styles = (stiboTheme: any) =>
  createStyles({
    buttonContainer: {
      width: "56px",
      height: "56px",
      margin: "8px 16px 8px 16px",
      boxSizing: "border-box",
      borderRadius: "2px",
      border: `solid 1px ${stiboTheme.palette.black[300]}`,
      backgroundColor: stiboTheme.colors.white,
      verticalAlign: "middle",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: 500
    }
  });

export interface ImageViewerProps {
  picturesLength: number;
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageViewerButton: React.FC<WithStyles<ImageViewerBaseClassName> & ImageViewerProps> = props => {
  const { picturesLength, setShowOverlay, classes } = props;

  return (
    <>
      {picturesLength > 5 && (
        <div onClick={() => setShowOverlay(true)} className={classes.buttonContainer}>
          {`+${picturesLength - 5}`}
        </div>
      )}
    </>
  );
};

export default withStiboStyles(styles)(ImageViewerButton);
