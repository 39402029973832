import * as React from "react";
import { WithStiboStyles, withStiboStyles, StyleRules, createStyles, Typography } from "tim-ui";

const styles = (stiboTheme): StyleRules<TitleBaseClassName> =>
  createStyles({
    container: {
      marginTop: "8px",
      marginBottom: "4px",
      wordBreak: "break-all"
    }
  });

interface TitleProps {
  children;
}

const Title = (props: TitleProps & WithStiboStyles<TitleBaseClassName>) => {
  const { classes, children } = props;
  return (
    <Typography variant="title" className={classes.container} id={"ProductSummaryHeader"}>
      {children}
    </Typography>
  );
};

export type TitleBaseClassName = "container";

export default withStiboStyles(styles)(Title);
