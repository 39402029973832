import { createStyles, StyleRules } from "tim-ui";
import { webkitScrollbar } from "../Scrollbar/webkitScrollbar";

type ProductAttributesEditorClasses =
  | "container"
  | "message"
  | "sectionList"
  | "sectionItem"
  | "sectionContainer"
  | "attributeGroupList"
  | "attributeGroupItem"
  | "attributeGroupContainer"
  | "attributeGroupLink"
  | "attributeList"
  | "attributeItem"
  | "valueControlContainer"
  | "valueControlLabel"
  | "valueControlInput"
  | "saveButton"
  | "wrapper"
  | "webkitScrollbar"
  | "overflowWrapper"
  | "background"
  | "cancelButton"
  | "thirdLevelHeader"
  | "secondLevelHeader"
  | "firstLevelHeader";

export const productSummaryHeight = 138;
export const toolbarHeight = 48;

const styles = (stiboTheme): StyleRules<ProductAttributesEditorClasses> =>
  createStyles({
    wrapper: {
      display: "flex",
      padding: "0em 2em 2em 2em",
      margin: 0,
      overflowY: "overlay" as any,
      height: `calc(100vh - 218px)`,
      outline: "none"
    },
    webkitScrollbar,
    overflowWrapper: {
      overflowY: "auto" as any // this is a fix for browser without support for overlay overflow
    },
    background: {
      backgroundColor: "transparent"
    },
    cancelButton: {
      color: stiboTheme.palette.black[300],
      backgroundColor: "#ffffff",
      fontWeight: 500,
      borderStyle: "none!important",
      "&:hover": {
        background: "rgba(63, 81, 181, 0.08)"
      }
    },
    container: {
      padding: "0 2em 0 0",
      margin: "auto",
      flexGrow: 4,
      height: `calc(100vh - ${productSummaryHeight}px - ${toolbarHeight}px)`,
      maxWidth: "60rem",
      minWidth: "40rem"
    },
    message: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.87)"
    },
    sectionList: {
      listStyleType: "none",
      margin: 0,
      paddingLeft: 0
    },
    sectionItem: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: stiboTheme.palette.black[300],
      width: "100%",
      marginTop: "2em",
      marginBottom: "2em",
      "&:first-child": {
        marginTop: 0
      },
      "&:last-child": {
        marginBottom: 0
      },
      "&:last-of-type": {
        overflowY: "hidden",
      }
    },
    sectionContainer: {
      width: "100%",
      paddingBottom: "0.3em",
      marginBottom: "1em",
      borderBottom: `1px solid ${stiboTheme.palette.black[50]}`
    },
    attributeGroupList: {
      listStyleType: "none",
      marginTop: "1em",
      paddingLeft: 0
    },
    attributeGroupItem: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.87)",
      width: "100%"
    },
    attributeGroupContainer: {
      width: "100%",
      marginBottom: "1em"
    },
    attributeGroupLink: {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 700,
      textDecoration: "none"
    },
    attributeList: {
      listStyleType: "none",
      marginTop: "1em",
      paddingLeft: 0
    },
    attributeItem: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.87)",
      width: "100%"
    },
    valueControlContainer: {
      padding: "0px 0px 8px 0px",
      margin: "0px!important"
    },
    valueControlLabel: {
      color: "rgba(0, 0, 0, 0.87)",
      background: "none"
    },
    valueControlInput: {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 400
    },
    saveButton: {
      position: "relative",
      marginBottom: "2em"
    },
    thirdLevelHeader: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      color: stiboTheme.palette.black[400],
      fontSize: "10px",
      height: "12px",
      letterSpacing: "1.5px"
    },
    secondLevelHeader: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      height: "24px",
      color: stiboTheme.palette.black[400],
      fontWeight: 500,
      fontSize: "20px"
    },
    firstLevelHeader: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      height: "16px",
      color: stiboTheme.palette.black[400],
      fontWeight: 500,
      fontSize: "14px"
    }
  });

export { ProductAttributesEditorClasses, styles };
