import * as React from "react";
import { StyleRules, createStyles, WithStiboStyles, withStiboStyles } from "tim-ui";
import { Moment } from "moment";

import Approved from "./Approved";
import PartlyApproved from "./PartlyApproved";
import NotInApprovedWorkspace from "./NotInApprovedWorkspace";
import { ApprovalStates } from "../../Product/types";

export type approvalState = "Approved" | "Unapproved changes" | "Never approved";

interface ApprovalStateProps {
  approvalDate?: Moment;
  approvalState: ApprovalStates;
}

export type ApprovalStateClasses = "approved" | "unapproved" | "neverApproved";

const styles = (stiboTheme): StyleRules<ApprovalStateClasses> =>
  createStyles({
    approved: {
      color: stiboTheme.palette.success[500]
    },
    unapproved: {
      color: stiboTheme.palette.danger[500]
    },
    neverApproved: {
      color: stiboTheme.palette.black[400]
    }
  });

class ApprovalState extends React.Component<WithStiboStyles<ApprovalStateClasses> & ApprovalStateProps> {
  render() {
    const { approvalDate, approvalState, classes } = this.props;
    switch (approvalState) {
      case ApprovalStates.CompletelyApproved:
        return <Approved date={approvalDate} className={classes.approved} />;
      case ApprovalStates.ApprovedInCurrentContext:
        return <Approved date={approvalDate} className={classes.approved} />;
      case ApprovalStates.PartlyApproved:
        return <PartlyApproved date={approvalDate} className={classes.unapproved} />;
      case ApprovalStates.NotInApprovedWorkspace:
        return <NotInApprovedWorkspace className={classes.neverApproved} />;
    }

    return null;
  }
}

export default withStiboStyles(styles)(ApprovalState);
