import { Moment } from "moment";
import moment = require("moment");

import { ShowInWorkbench } from "./showInWorkbench";
import {
  AttributeGroup,
  Image,
  AttributeGroupTO,
  Attribute,
  ValueEdge,
  IProduct,
  ProductTO,
  EdgeWithImage,
  ValuesTO,
  ApprovalStates,
  sort,
  removeEmptyChildrenAndAttributes,
  setChildGroups,
  CreateAttributeGroupProps,
  AttrValueGroups,
  EdgeTO
} from "./types";
import { AttributeValueBase } from "../ProductEditor/formStore";
import { observable, runInAction, action } from "mobx";
import { batchAttributeGroupRequest } from "../ProductViewer/client/client";

export class ProductForEditor implements IProduct {
  stepId: string;
  title: string;
  path: string;
  topAttributeGroup: AttributeGroup | undefined;
  attributeGroups: AttributeGroup[];
  primaryImage: Image | undefined = undefined;
  productImages: Image[] = [];
  showInWorkbench = new ShowInWorkbench();
  currentRevision: string;
  approvalState: ApprovalStates;
  approvalDate?: Moment;
  lastUpdateDate?: Moment;
  productForEditor: ProductForEditor;

  nodeType: "product";
  attributeGroupMap: Map<string, AttributeGroupTO | undefined>;
  attributeGroupMapForNewValues: Map<string, AttributeGroupTO | undefined> = new Map();

  constructor(
    private productTO: ProductTO,
    private batchAttributeGroup: batchAttributeGroupRequest,
    attributeGroupMap: Map<string, AttributeGroupTO | undefined>,
    private onProductForEditorIsReady: () => void
  ) {
    const { stepId, title, path, currentRevision, approvalState } = productTO;
    this.stepId = stepId;
    this.title = title;
    this.path = path;
    this.currentRevision = currentRevision;
    this.approvalState = approvalState;
    this.lastUpdateDate = productTO.lastUpdateDate ? moment(productTO.lastUpdateDate) : undefined;
    this.approvalDate = this.lastUpdateDate;
    this.attributeGroupMap = attributeGroupMap;

    this.initialize(productTO);
  }
  @observable isProcessing = true;

  @observable updateCounter = 0;

  @action
  reset = (productTO: ProductTO, callback) => {
    this.isProcessing = true;
    this.initialize(productTO, callback);
  };

  private initialize = async (productTO: ProductTO, callback?: () => void) => {
    const allValues = productTO.values ? productTO.values.edges : [];

    allValues.forEach(fv => {
      (fv &&
        fv.node &&
        fv.node.attribute &&
        fv.node.attribute.attributeGroups.forEach(ag => {
          if (this.attributeGroupMap.get(ag.stepId) === undefined) {
            this.attributeGroupMapForNewValues.set(ag.stepId, undefined);
          }
        })) ||
        [];
    });

    const attributeGroupArray = Array.from(this.attributeGroupMapForNewValues.keys());
    if (attributeGroupArray.length > 0) {
      const response = await this.batchAttributeGroup(attributeGroupArray).then(response => {
        Object.keys(response.data).forEach(key => {
          const attributeGroup: AttributeGroupTO = response.data[key];
          this.attributeGroupMap.set(attributeGroup.stepId, attributeGroup);
        });
        this.onGroupsRequestFinished(allValues, callback);
      });
    } else {
      // simulating async
      requestAnimationFrame(() => this.onGroupsRequestFinished(allValues, callback));
    }
  };

  private onGroupsRequestFinished = (edges: EdgeTO[], callback?: () => any) => {
    this.attributeGroups = this.mapRawDataToAttributeGroups({ edges }, this.attributeGroupMap);
    const topAttributeGroup = this.attributeGroups.find(group => group.level === 0);

    this.topAttributeGroup = topAttributeGroup ? sort(removeEmptyChildrenAndAttributes(topAttributeGroup)) : undefined;
    runInAction(() => {
      this.isProcessing = false;
      if (callback) {
        this.updateCounter = this.updateCounter + 1;
      } else {
        this.onProductForEditorIsReady();
      }
    });
    callback && callback();
  };

  private mapRawDataToAttributeGroups = (values: ValuesTO, attributeGroupsMap: Map<string, AttributeGroupTO | undefined>): AttributeGroup[] => {
    let attrGroups: Map<string, AttributeGroup> = new Map<string, AttributeGroup>();
    values.edges.map(({ node }: { node: ValueEdge }) => {
      const { attribute } = node;
      const fixedAttribute = attribute ? this.showInWorkbench.fixAttributeHierarchy(attribute, attributeGroupsMap) : undefined;

      if (fixedAttribute) {
        fixedAttribute.attributeGroups.forEach((attribGroupTO: AttributeGroupTO) => {
          const thisValueGroup: AttributeGroup | undefined =
            attribGroupTO && attribGroupTO.showInWorkbench ? this.getOrCreateAttributeGroup(attribGroupTO, attrGroups) : undefined;

          if (thisValueGroup) {
            const displaySequence: string | undefined =
              fixedAttribute.displaySequence && fixedAttribute.displaySequence.simpleValue ? fixedAttribute.displaySequence.simpleValue : undefined;

            thisValueGroup.setAttribute(
              fixedAttribute.stepId,
              new Attribute(
                fixedAttribute.stepId,
                fixedAttribute.title,
                node.simpleValue,
                displaySequence,
                fixedAttribute.isConditionallyValid,
                (node as any) as AttributeValueBase
              )
            );
          }
        });
      }
    });

    attrGroups = setChildGroups(attrGroups);

    const attributeGroups: AttributeGroup[] = Array.from(attrGroups.values());
    return attributeGroups;
  };

  private createAttributeGroup = ({
    parent,
    title,
    showInWorkbench,
    displaySequence,
    attributeGroupSection,
    attrValueGroups,
    stepId
  }: CreateAttributeGroupProps) => {
    const attributeGroup = new AttributeGroup({
      stepId,
      title,
      showInWorkbench,
      displaySequence,
      parent: parent ? this.getOrCreateAttributeGroup(parent, attrValueGroups) : undefined
    });

    if (attributeGroupSection) {
      attributeGroup.withSection(attributeGroupSection);
    }

    return attributeGroup;
  };

  private getOrCreateAttributeGroup = (attributeGroupTO: AttributeGroupTO, attrValueGroups: AttrValueGroups): AttributeGroup | undefined => {
    const { displaySequence, ...rest } = attributeGroupTO;

    if (attrValueGroups.has(attributeGroupTO.stepId)) {
      return attrValueGroups.get(attributeGroupTO.stepId);
    } else {
      const attributeGroup = this.createAttributeGroup({
        ...rest,
        attrValueGroups,
        displaySequence: displaySequence && displaySequence.simpleValue ? parseInt(displaySequence.simpleValue, 10) : undefined
      });

      attrValueGroups.set(attributeGroup.stepId, attributeGroup);

      return attributeGroup;
    }
  };
}
