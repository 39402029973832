import * as React from "react";
import { createStyles, WithStiboStyles, withStiboStyles, Link } from "tim-ui";

const NO_DATA_AVAILABLE: string = "No data available";

interface EmptyProductMessageProps {
  className?: string;
  productTitle: string;
  onEditModeOpen?: () => void;
}

export const styles = stiboTheme =>
  createStyles({
    exceptionMessage: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif",
      fontSize: "20px",
      fontWeight: 500,
      textAlign: "center",
      marginTop: "72px",
      height: "100vh",
      color: stiboTheme.palette.black[300]
    }
  });

type Classes = "exceptionMessage";

const EmptyProductMessage = (props: EmptyProductMessageProps & WithStiboStyles<Classes>) => (
  <div key={"emptyProductMessage"} className={props.classes.exceptionMessage}>
    <p>{NO_DATA_AVAILABLE}</p>
    <Link onClick={props.onEditModeOpen}>Update {props.productTitle}</Link>
  </div>
);

export default withStiboStyles(styles)(EmptyProductMessage);
