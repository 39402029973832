import {
  AttributeMultiValue,
  AttributeSingleValue,
  FormControlEvent,
  TypeaheadProvider,
  ValidationMessage,
  insertValidListOfValues,
  FormField
} from "@stibo/value-components";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Attribute, LovValuesTO } from "../../Product/types";
import { AttributeValueBase } from "../formStore";
import { IProductEditorStore } from "../productEditorStore";
import { useLovTypeahead } from "../useLovTypeahead";


const webUIlocaleProvidedThroughBridge: string = "dk-DK"; // todo remove hardcoded locals

const pixelsOverscan = window.innerHeight * 2;

export interface ValueControlProps {
  name: string;
  stepId: string;
  title: string;
  value: AttributeSingleValue | AttributeMultiValue;
  valueControlContainerClassName: string;
  valueControlLabelClassName: string;
  valueControlInputClassName: string;
  valueBlurHandler: (e: FormControlEvent<AttributeValueBase>) => void;
  valueChangeHandler: (e: FormControlEvent<AttributeValueBase>) => void;
  messages?: ValidationMessage[];
  store: IProductEditorStore;
  attribute?: Attribute;
  lovs?: LovValuesTO;
}

@observer
class ValueControlReactiveViewPortedWrapper extends React.Component<ValueControlProps & { sectionRef: any }> {
  componentDidUpdate() {
    const { form } = this.props.store;
    const field = form && form.fields.find(f => f.name === this.props.name);
    requestAnimationFrame(() => {
      if (field && this.element && this.isVisible) {
        field.height = this.element.clientHeight;

      }
    });

  }

  ref = React.createRef();
  async componentDidMount() {
    const observer = new IntersectionObserver(([entry]) => {
      this.onVisibilityChanged(entry.isIntersecting);
    }, {
        root: this.props.sectionRef.current,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0,
      });

    if (this.ref.current) {
      observer.observe(this.ref.current as any);
    }
  }

  @action
  onVisibilityChanged = (isVisible: boolean) => {
    this.isVisible = isVisible;
  }

  @observable isVisible = false;

  element;

  getMargin = (field): string => {
    const isMultivalue = field && (field.value as AttributeMultiValue).values && (field.value as AttributeMultiValue).values.slice().length > 1;
    return isMultivalue ? "calc(1em + 8px) 0" : "";
  }

  render() {
    const { form } = this.props.store;

    const field = form && form.fields.find(f => f.name === this.props.name);
    if (this.isVisible) {
      if (field) {
        const trigger = field.updateCounter;
        const value = field.value;
        const isError = field.messages.some((m: ValidationMessage) => m.severity === "error");
        return (
          <div style={{
            position: "relative",
            margin: this.getMargin(field)
          }}>
            <div
              style={{ position: "absolute", top: -pixelsOverscan, bottom: -pixelsOverscan }}
              ref={this.ref as any}>&nbsp;</div>
            <div ref={(element) => this.element = element}>
              <ValueControlWrapper
                {...this.props}
                value={{
                  ...value,
                  ...(value!.attribute && { attribute: value.attribute })
                }}
                valueChangeHandler={(e: FormControlEvent<AttributeValueBase>) => {
                  form && form.onUpdateValue(field, e.value);
                }}
                valueBlurHandler={(e: FormControlEvent<AttributeValueBase>) => {
                  form && form.onControlBlur(field, e.value);
                }}
                messages={isError ? field.messages : undefined}
                lovs={field.LOVs ? field.LOVs.data.product.validLoVValues : undefined}
              />
            </div>
          </div>
        );
      }
    }
    else {
      return <div
        style={{
          position: "relative",
          height: field && field.height,
          margin: this.getMargin(field)
        }}
      >
        <div style={{ position: "absolute", top: -pixelsOverscan, bottom: -pixelsOverscan }} ref={this.ref as any}>&nbsp;</div>
      </div>;
    }

    return null;
  }
}

export { ValueControlReactiveViewPortedWrapper };

const ValueControlWrapper = (props: ValueControlProps) => {
  const { isConditionallyValid, ...attribute } = (props.value as AttributeValueBase).attribute;

  const myTypeaheadFn = useLovTypeahead(attribute.listOfValues && attribute.listOfValues.stepId);
  const { lovs, valueChangeHandler, valueBlurHandler, name, messages } = props;

  const value = lovs ? insertValidListOfValues(props.value, lovs) : props.value;

  if (isConditionallyValid === false) {
    return null;
  }

  return (
    <TypeaheadProvider typeaheadFn={myTypeaheadFn}>
      <FormField
        value={value}
        valueChangeHandler={valueChangeHandler}
        valueBlurHandler={valueBlurHandler}
        name={name}
        locale={webUIlocaleProvidedThroughBridge}
        rootClassName={props.valueControlContainerClassName}
        labelClassName={props.valueControlLabelClassName}
        inputClassName={props.valueControlInputClassName}
        messages={messages}
        readOnly={false}
      />
    </TypeaheadProvider>
  );
};

export default ValueControlWrapper;
