// import { BusinessObjectMember } from "./ProductViewer/BusinessObjects/types";
import { ISection } from "./Sections/types";

export enum ScreenMode {
  edit = "edit",
  view = "view"
}

// export type GroupedBusinessObjects = { sections: GroupedSections; members: Array<BusinessObjectMember> };
// type GroupedSections = { [key: string]: ISection };

export interface AppConfig {
  stepId: string;
  nodeType: string;
  workspaceID: string;
  contextID: string;
  hasSufficiencyLicence: boolean;
  isSyndigoAvailable: boolean;
  thumbnailURL?: string;
  canNavigatePath: boolean;
}
