import gql from "graphql-tag";

export const evaluationDetails = gql`
  fragment EvaluationDetails on SufficiencyEvaluationDetails {
    metricId
    metricName
    metricParentId
    metricType
    calculationDate
    score
    showAsBoolean
    severityLevel
    evaluationDetails {
      metricId
      metricName
      calculationDate
      score
      severityLevel
      showAsBoolean
      messageHolders {
      dataType {
        stepId
        title
      }
      messages {
        severityLevel
        message
      }
    }
    }
  }
`;

export const sufficiencyResult = gql`
  fragment Result on SufficiencyResult {
    severityLevel
    description
    evaluationDetails {
      ...EvaluationDetails
    }
  }
`;
