import {createStyles, StyleRules, withStiboStyles, WithStiboStyles} from "tim-ui";
import * as React from "react";
import {useCallback, useEffect, useRef} from "react";
import PopupContent from "./PopupContent";

const styles = (stiboTheme): StyleRules<PopupContainerBaseClassName> => {
    return createStyles({
        separator: {
            float: "left",
            border: "none",
            padding: "0px",
            marginTop: "10px",
            marginRight: "8px",
            background: "none",
            color: stiboTheme.palette.black[200],
            "&:active": {
                color: stiboTheme.palette.navigation[500],
            },
            "&:focus": {
                color: stiboTheme.palette.navigation[500],
            },
            "&:hover": {
                color: stiboTheme.palette.black[300],
            },
            "&:disabled": {
                cursor: "default",
                color: stiboTheme.palette.black[50],
            },
        },
    });
};
export type PopupContainerBaseClassName = "separator";

interface PopupContainerBase {
    parentNodes: string[];
    onNodeClick(nodeIndex: number, nodeTitle: string);
}

const PopupContainer = (props: PopupContainerBase & WithStiboStyles<PopupContainerBaseClassName>) => {
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
    const toggle = (isOpen) => {
        return setIsPopupOpen(!isOpen);
    };
    const getLeftPosition = useCallback(() => {
        const navigationPanel = document.getElementsByClassName("primary-navigation-panel")[0];
        if (navigationPanel) {
            return navigationPanel.clientWidth;
        }
        return 0;
    }, []);
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
        const checkIfClickedOutside = e => {
            let curr = ref.current;
            if (curr) {
                if (curr.contains(e.target)) {
                    return;
                } else {
                    setIsPopupOpen(false);
                }
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);
    return (
        <div ref={ref}>
            <button className={props.classes.separator} onClick={() => toggle(isPopupOpen)}>...</button>
            {isPopupOpen ? (<PopupContent parentNodes={props.parentNodes} onNodeClick={props.onNodeClick} leftPosition={getLeftPosition()}/>) : (<div/>)}
        </div>
    );
};
export default withStiboStyles(styles)(PopupContainer);