import * as React from "react";
import { generateTestID } from "../../common/generateTestID";

interface OverlineHeaderProps {
  className: string;
  title: string;
}

const OverlineHeader = (props: OverlineHeaderProps) => {
  const { className, title } = props;

  return (
    <div className={className} id={`CardContentLabel_AttributeGroupCard_${generateTestID(title)}`}>
      {title}
    </div>
  );
};

export { OverlineHeader };
