import React = require("react");
import { CardContent } from "tim-ui";

import AttributesTableCard from "./AttributesTableCard";
import { OverlineHeader } from "./OverlineHeader";
import { FlatGroup } from "./FlatGroup";
import { ElementNames, generateElementId } from "../../common/dataUtils";
import { AttributeGroup } from "../screen/ProductDetailsScreen/Product/types";

const ThirdLevelGroup = (props: { attributeGroup: AttributeGroup; classes }): JSX.Element => {
  const { classes, attributeGroup } = props;
  const { stepId, title, attributes } = attributeGroup;
  const elementId: string = generateElementId(ElementNames.attributeGroup, title);

  return (
    <div id={elementId}>
      <CardContent className={classes.content} id={`CardContent_AttributeGroupCard_${stepId}`}>
        <OverlineHeader title={title} className={classes.tileHeading} />
        {attributeGroup.childGroups.map(group => attributeGroupRenderer(classes, group))}
      </CardContent>
    </div>
  );
};

const attributeGroupRenderer = (classes, attributeGroup: AttributeGroup) => {
  return {
    flat: <FlatGroup classes={classes} group={attributeGroup} key={attributeGroup.stepId} />,
    twoLevels: <SecondLevel classes={classes} attributeGroup={attributeGroup} key={attributeGroup.stepId} />,
    threeLevels: <ThreeLevel classes={classes} attributeGroup={attributeGroup} key={attributeGroup.stepId} />
  }[attributeGroup.displayType];
};

const SecondLevel = (props: { classes; attributeGroup }): JSX.Element => {
  const { classes, attributeGroup } = props;

  return (
    <>
      {
        <AttributesTableCard
          key={attributeGroup.stepId}
          title={attributeGroup.title}
          titleStyle={classes.overlineHeading}
          attributes={attributeGroup.attributes}
        />
      }
      {attributeGroup.childGroups.map(group => {
        return attributeGroupRenderer(classes, group);
      })}
    </>
  );
};

export const ThreeLevel = (props: { classes; attributeGroup }): JSX.Element => {
  const { classes, attributeGroup } = props;

  return (
    <>
      <AttributesTableCard
        key={attributeGroup.stepId}
        title={attributeGroup.title}
        titleStyle={classes.overlineHeading}
        attributes={attributeGroup.attributes}
      />
      {attributeGroup.childGroups.map(group => {
        return attributeGroupRenderer(classes, group);
      })}
    </>
  );
};

export { ThirdLevelGroup };
