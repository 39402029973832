import { Moment } from "moment";
import { AttributeMultiValue, AttributeSingleValue } from "@stibo/value-components";

export type ValueEdge = {
  simpleValue: string;
  attribute?: StepAttribute;
};

export type StepAttribute = {
  stepId: string;
  title: string;
  displaySequence?: SimpleAttributeValue;
  attributeGroups: LimitedAttributeGroupTO[];
  hasListOfValues?: boolean;
  listOfValues?: ListOfValues;
  isConditionallyValid?: boolean;
};

export type ListOfValues = {
  stepId: string;
  validValues: LovValuesTO;
};
export type AttributeGroupTO = {
  title: string;
  stepId: string;
  parent: AttributeGroupTO | null;
  showInWorkbench: boolean;
  displaySequence?: ValueEdge;
  attributeGroupSection?: LOV;
};

export type LimitedAttributeGroupTO = {
  stepId: string;
  showInWorkbench: boolean;
};


export type LOV = {
  simpleValue: string | null;
  valueStepId: string | null;
};

export type SimpleAttributeValue = {
  simpleValue: string | null;
};

export type AttributeGroupSection = {
  label: string;
  orderId: number;
};

export enum ApprovalStates {
  NotInApprovedWorkspace = 0,
  PartlyApproved,
  ApprovedInCurrentContext,
  CompletelyApproved
}

export type IProduct = {
  isProcessing: boolean;
  stepId: string;
  title: string;
  path: string;
  topAttributeGroup: AttributeGroup | undefined;
  attributeGroups: AttributeGroup[];
  primaryImage: Image | undefined;
  productImages: Image[];
  currentRevision: string;
  approvalState: ApprovalStates;
  nodeType: string;
  approvalDate?: Moment;
  lastUpdateDate?: Moment;
  attributeGroupMap: Map<string, AttributeGroupTO | undefined>;
  productForEditor: IProduct;
  reset?: (productTO: ProductTO, callback: () => void) => void;
  updateCounter: number;
  isProductForEditorReady?: boolean;
};

export interface ValuesTO {
  edges: EdgeTO[]; // todo discover this type
}
export interface ReferencesTO {
  edges: EdgeTO[]; // todo discover this type
}

export type EdgeTO = {
  node: NodeTO;
};

export type NodeTO = {
  primaryImage?: PrimaryImageTO | undefined;
  simpleValue?: string;
  attribute?: StepAttribute;
};

export type EdgeWithImage = {
  node: NodeWithImage;
};

type NodeWithImage = {
  primaryImage: PrimaryImageTO;
};

type PrimaryImageTO = {
  url: string;
  thumbnail: string;
  title: string;
};

type ImageTO = {
  url: string;
  title: string;
  thumbnail: string;
};

export const EmptyImage: Image = {
  title: "NONE",
  url: "NONE",
  thumbnail: "NONE"
};

export type ProductTO = {
  stepId: string;
  title: string;
  path: string;
  values: ValuesTO | undefined;
  references: ReferencesTO | undefined;
  primaryImage: ImageTO | undefined;
  nodeType: string;
  currentRevision: string;
  approvalState: number;
  lastUpdateDate: string;
};

export type ProductLovTO = {
  validLoVValues: LovValuesTO;
};

export interface LovValuesTO {
  edges: LovEdgeTO[];
}
export type LovEdgeTO = {
  node: LovNodeTO;
};
export type LovNodeTO = {
  value: string;
  unit: Unit | null;
};

export type Unit = {
  stepId: string;
  title: string;
};

export class Attribute {
  stepId: string;
  title: string;
  value: string;
  displaySequence: number | undefined;
  isConditionallyValid?: boolean;
  attributeValue?: AttributeSingleValue | AttributeMultiValue;

  constructor(
    // todo wrap params into object avoid long constructors
    stepId: string,
    title: string,
    value: string | null,
    displaySequence: string | undefined,
    isConditionallyValid?: boolean,
    attributeValue?: AttributeSingleValue | AttributeMultiValue
  ) {
    this.stepId = stepId;
    this.title = title;
    this.value = value === null ? "" : value;
    this.displaySequence = displaySequence ? this.withDisplaySequence(displaySequence) : undefined;
    this.isConditionallyValid = isConditionallyValid;
    this.attributeValue = attributeValue;
  }

  withDisplaySequence(sequence: string): number | undefined {
    const dispSeq: number = parseInt(sequence, 10);
    return isNaN(dispSeq) || dispSeq < 0 ? undefined : dispSeq;
  }
}

export type Image = {
  title: string;
  url: string;
  thumbnail: string;
};

export const UNGROUPED_ATTRIBUTE_SECTION: AttributeGroupSection = {
  label: "Not sectioned",
  orderId: 0
};

const mapToSection = (sectionTO: LOV): AttributeGroupSection => {
  return {
    label: sectionTO && sectionTO.simpleValue ? sectionTO.simpleValue : UNGROUPED_ATTRIBUTE_SECTION.label,
    orderId: sectionTO && sectionTO.valueStepId ? parseInt(sectionTO.valueStepId, 10) : UNGROUPED_ATTRIBUTE_SECTION.orderId
  };
};

export type parent = AttributeGroup | undefined | null; // todo we should remove null from allowed types

export type displayType = "threeLevels" | "twoLevels" | "flat" | "empty";

interface AttributeGroupProps {
  parent: parent;
  stepId: string;
  title: string;
  showInWorkbench?: boolean;
  displaySequence?: number;
  level?: number;
}

export class AttributeGroup {
  private _attributes: Map<string, Attribute> = new Map<string, Attribute>();
  private _showInWorkbench?: boolean;
  private _displaySequence?: number;
  private _parent: parent;
  private _childGroups: AttributeGroup[] = [];
  private _stepId: string;
  private _title: string;
  section: AttributeGroupSection = UNGROUPED_ATTRIBUTE_SECTION;


  constructor({ parent, stepId, title, showInWorkbench, displaySequence }: AttributeGroupProps) {
    this._parent = parent;
    this._stepId = stepId;
    this._title = title;
    this._displaySequence = displaySequence;
    this._showInWorkbench = showInWorkbench;
  }

  withParent(parent: AttributeGroup) {
    this._parent = parent;
    return this;
  }

  isSectionRoot = () => {
    return this.parent && this.parent.section !== this.section;
  };

  withAttributes(attributes: Attribute[]): AttributeGroup {
    this._attributes = new Map();
    attributes.forEach(attrib => this._attributes.set(attrib.stepId, attrib));
    return this;
  }

  withShowInWorkbench(showInWorkbench: boolean): AttributeGroup {
    this._showInWorkbench = showInWorkbench === undefined ? true : showInWorkbench; // true by default in Step
    return this;
  }

  withDisplaySequence(sequence: string): AttributeGroup {
    const dispSeq: number = parseInt(sequence, 10);
    this._displaySequence = isNaN(dispSeq) || dispSeq < 0 ? undefined : dispSeq;
    return this;
  }

  withTitle(title: string): AttributeGroup {
    this._title = title;
    return this;
  }

  withSection(section: LOV): AttributeGroup {
    if (this.parent) {
      this.section = section && section.simpleValue === null ? this.parent.section : mapToSection(section);
    } else {
      this.section = section && section.simpleValue === null ? UNGROUPED_ATTRIBUTE_SECTION : mapToSection(section);
    }

    return this;
  }

  withChildGroups(childGroups: AttributeGroup[]) {
    this._childGroups = childGroups;
    return this;
  }

  setAttribute(stepId: string, attribute: Attribute) {
    this._attributes.set(stepId, attribute);
  }

  hasAttributes(): boolean {
    return this._attributes.size > 0;
  }

  isFilled(): boolean {
    return (
      this._childGroups.some(group => {
        return group.isFilled();
      }) || this.hasAttributes()
    );
  }

  hasChildren(): boolean {
    return this._childGroups.length > 0;
  }

  addChildGroup(value: AttributeGroup): AttributeGroup {
    this._childGroups.push(value);
    return this;
  }

  get childGroups(): AttributeGroup[] {
    return this._childGroups;
  }

  get attributesMap(): Map<string, Attribute> {
    return this._attributes;
  }

  get attributes(): Attribute[] {
    return Array.from(this._attributes).map(entry => entry[1]);
  }

  get showInWorkbench(): boolean | undefined {
    return this._showInWorkbench;
  }

  get displaySequence(): number | undefined {
    return this._displaySequence;
  }

  get level(): number {
    return this.parent ? this.parent.level + 1 : 0;
  }

  get title(): string {
    return this._title;
  }

  get parent(): parent {
    return this._parent;
  }

  get stepId(): string {
    return this._stepId;
  }

  get displayType(): displayType {
    if (!this.hasChildren() && this.hasAttributes()) {
      return "flat";
    }

    const hasThreeLevels = this.childGroups.some(group => {
      return group.childGroups.some(g => {
        return g.hasChildren() || g.hasAttributes();
      });
    });

    if (hasThreeLevels) {
      return "threeLevels";
    }

    return "twoLevels";
  }
}

export const removeEmptyChildrenAndAttributes = (rootAttributeGroup: AttributeGroup): AttributeGroup => {
  rootAttributeGroup = removeHiddenInWorkbenchAttributes(rootAttributeGroup);

  const childGroups: AttributeGroup[] = rootAttributeGroup.childGroups.filter(childGroup => {
    removeEmptyChildrenAndAttributes(childGroup);
    return childGroup.hasAttributes() || childGroup.hasChildren();
  });
  return rootAttributeGroup.withChildGroups(childGroups);
};

const removeHiddenInWorkbenchAttributes = (attributeGroup: AttributeGroup): AttributeGroup => {
  return attributeGroup.showInWorkbench ? attributeGroup : attributeGroup.withAttributes([]);
};

type AttributeGroupDisplaySequenceSort = {
  displaySequence: number;
  title: string;
};

const displaySequenceComparator = (a: AttributeGroupDisplaySequenceSort, b: AttributeGroupDisplaySequenceSort): number => {
  const { displaySequence: displaySequenceA } = a;
  const { displaySequence: displaySequenceB } = b;

  return displaySequenceA === displaySequenceB ? stringComparator(a.title, b.title) : displaySequenceA > displaySequenceB ? 1 : -1;
};

const stringComparator = (a: string, b: string): number => {
  return a.localeCompare(b);
};

const attributeGroupComparator = (a: AttributeGroup, b: AttributeGroup) => {
  if (a.level === 0) {
    return -1;
  }

  const { displaySequence: displaySequenceA } = a;
  const { displaySequence: displaySequenceB } = b;

  return displaySequenceA && displaySequenceB
    ? displaySequenceComparator({ displaySequence: displaySequenceA, title: a.title }, { displaySequence: displaySequenceB, title: b.title })
    : displaySequenceA
      ? -1
      : displaySequenceB
        ? 1
        : stringComparator(a.title, b.title);
};

export const sort = (attributeGroup: AttributeGroup): AttributeGroup => {
  const sortedChildGroups: AttributeGroup[] = attributeGroup.childGroups.sort(attributeGroupComparator);
  const sortedAttributes: Attribute[] = sortAttributes(attributeGroup.attributes);
  attributeGroup = attributeGroup.withAttributes(sortedAttributes);

  sortedChildGroups.forEach(childGroup => sort(childGroup));

  return attributeGroup.withChildGroups(sortedChildGroups);
};

// todo make it more readable

const attributeComparator = (a: Attribute, b: Attribute) => {
  let displaySequenceA = a.displaySequence;
  let displaySequenceB = b.displaySequence;

  if (displaySequenceA === undefined) {
    if (displaySequenceB === undefined) {
      return stringComparator(a.title, b.title);
    } else {
      return 1;
    }
  } else {
    if (displaySequenceB === undefined) {
      return -1;
    } else {
      return displaySequenceComparator({ displaySequence: displaySequenceA, title: a.title }, { displaySequence: displaySequenceB, title: b.title });
    }
  }
};

export const sortAttributes = (attributes: Attribute[]): Attribute[] => {
  return attributes.sort(attributeComparator);
};

export const setChildGroups = (attrValueGroups: Map<string, AttributeGroup>): Map<string, AttributeGroup> => {
  attrValueGroups.forEach((group: AttributeGroup) => {
    const grpParent: parent = group.parent;
    if (grpParent) {
      grpParent.addChildGroup(group);
    }
  });
  return attrValueGroups;
};

export interface CreateAttributeGroupProps {
  parent: AttributeGroupTO | null;
  stepId: string;
  title: string;
  showInWorkbench?: boolean;
  displaySequence: number | undefined;
  level?: number;
  attrValueGroups: AttrValueGroups;
  attributeGroupSection?: LOV;
}

export type AttrValueGroups = Map<string, AttributeGroup>;
