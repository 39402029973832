import gql from "graphql-tag";

export const collectionEdges = gql`
  fragment Edges on ValueConnection {
    edges {
      node {
        attribute {
          displaySequence {
            simpleValue
          }
          attributeGroups {
            ...AttributeGroup
          }
          helptext: value(attributeStepId: "AttributeHelpText") {
            simpleValue
          }
          stepId
          title
          isDerived
          hasListOfValues
          isConditionallyValid: isConditionallyValidForProduct(stepId: $stepId)
          listOfValues {
            stepId
            isUsingValueIds
            size
            isMedium
            validValues(first: 10) {
              pageInfo {
                hasNextPage
              }
              edges {
                node {
                  externalId
                  value
                  unit {
                    stepId
                    title
                  }
                }
              }
            }
          }
          isMultiValued
          validator {
            name
            minValue
            maxValue
            mask
            maxLength
          }
          validUnits {
            stepId
            title
          }
          defaultUnit {
            stepId
            title
          }
        }
        canSetValue
        simpleValue
        ... on SingleValue {
          value
          valueStepId
          unit {
            stepId
            title
          }
        }
        ... on MultiValue {
          values {
            value
            valueStepId
            unit {
              stepId
              title
            }
          }
        }
      }
    }
  }
`;
