import * as React from "react";
import { useState } from "react";
import { withStiboStyles, WithStiboStyles } from "tim-ui";
import {
  BridgeCallbacks,
  withBridgeCallbacks,
  WithBridgeCallbacks,
  WithBridgeContext,
  useTimGraphClient,
} from "tim-bridge";

import { ProductNotFoundMessage } from "./Errors";
import { styles, TClasses } from "./ProductDetailsScreen.styles";
import { AppConfig, ScreenMode } from "./types";
import { IEditClient } from "./ProductEditor/client/client";
import Modal from "../../Modal/Modal";
import { ProductEditorModalContent } from "./ProductEditor/ProductEditorModalContent";
import ProductViewer from "./ProductViewer/ProductViewer";
import { ProductEditorStore } from "./ProductEditor/productEditorStore";
import { IProductViewerStore } from "./ProductViewer/ProductViewerStore";


export type ProductDetailsScreenInnerProps = {
  editClient: IEditClient;
  config: AppConfig;
  productViewerStore: IProductViewerStore;
};

export type ProductDetailsScreenProps =
  ProductDetailsScreenInnerProps &
  WithStiboStyles<TClasses> &
  Partial<WithBridgeContext> &
  Partial<WithBridgeCallbacks>;

export type BridgeCallbacksProps = {
  setDirty?: (val: boolean) => boolean;
  onSave?: () => boolean;
} & BridgeCallbacks;


const ProductDetailsScreen: React.FC<ProductDetailsScreenProps> = props => {
  const [screenMode, setScreenMode] = useState<ScreenMode>(ScreenMode.view);

  const { config, bridgeCallbacks, editClient } = props;

  if (!config.stepId) {
    return (
      <ProductNotFoundMessage
        selection={{
          stepId: config.stepId,
          __typename: config.nodeType
        }}
      />
    );
  }

  if (screenMode === ScreenMode.edit) {
    return <Modal isOpen={true} onClose={() => null}>
      <ProductEditorModalContent
        store={new ProductEditorStore({
          editClient: editClient,
          bridgeCallbacks,
          onEditClosed: () => {
            setScreenMode(ScreenMode.view);
          },
          product: props.productViewerStore.product
        })}
        config={config}
        bridgeCallbacks={bridgeCallbacks}
      />
    </Modal>;
  } else {

    return (
      <ProductViewer
        productViewerStore={props.productViewerStore}
        onEditOpened={() => setScreenMode(ScreenMode.edit)}
        config={config}
        bridgeCallbacks={bridgeCallbacks}
      />
    );
  }
};

export default withStiboStyles(styles)(withBridgeCallbacks(ProductDetailsScreen));

export { ProductDetailsScreen };
