import * as React from "react";
import {Typography, WithStiboStyles, withStiboStyles} from "tim-ui";
import { WithTranslation, TimGraphApollo, withGraphqlEndpoint } from "tim-bridge";
import { nsTranslate } from "../../../../i18n";
import { TClasses, styles } from "../Sections/Section.styles";
import { SyndigoContent } from "@stibo/ui-syndigo-integration";
import { AppConfig } from "../types";
import classnames = require("classnames");
import { WithApolloClient } from "react-apollo/withApollo";
export type EnhancedMarketingContentProps = {
  appConfig: AppConfig; // to avoid name collision with graphQL client config
};

const EnhancedMarketingContent = (
  props: EnhancedMarketingContentProps & WithStiboStyles<TClasses> & WithTranslation & WithApolloClient<{ client: TimGraphApollo }>
) => {
  const { classes, t, appConfig: config, client: syndigoGraphQLClient } = props;

  const noContentElement = (
    <div className={classnames(classes.wrapper, classes.exceptionMessage)}>{t("noEnhancedMarketingContent.text", { defaultValue: "No Syndigo Content" })}</div>
  );

  return (
    <section className={classes.container}>
      <header>
          <Typography variant="subheading" className={classes.header}>{t("enhancedMarketingContent.text", { defaultValue: "Enhanced Marketing Information" })}</Typography>
      </header>
      <SyndigoContent
        className={classes.wrapper}
        syndigoGraphQLClient={syndigoGraphQLClient}
        config={{ stepId: config.stepId, workspaceId: config.workspaceID, contextId: config.contextID }}
        noContentElement={noContentElement}
      />
    </section>
  );
};

export default withGraphqlEndpoint("syndigo-graphql")(withStiboStyles(styles)(nsTranslate(EnhancedMarketingContent))) as React.FC<
  EnhancedMarketingContentProps
>;
