import * as React from "react";
import { Tooltip } from "tim-ui";

import { nsTranslate } from "../../../../../i18n";
import { TagProps, ApprovedInnerProps, ApprovedProps } from "../types";

const Approved = (props: ApprovedInnerProps & TagProps) => {
  const { date, className, t } = props;
  const tag = (
    <span className={className} id={"ProductSummaryApproved"}>
      {t("approved.text", { defaultValue: "Approved" })}
    </span>
  );

  return date ? (
    <Tooltip title={`${t("approved.tooltip", { defaultValue: "Last Approved on" })} ${date.format("LLLL")}`} placement="top">
      {tag}
    </Tooltip>
  ) : (
      tag
    );
};

export default (nsTranslate)(Approved);
