import gql from "graphql-tag";

export const primaryAndReferencedProductImagesFragment = gql`
  fragment Images on Product {
    primaryImage {
      title
      thumbnail: contentUrl(pipelineStepId: "plain-thumbnail")
      url: contentUrl(pipelineStepId: "web-proof-xlarge")
    }
    references(referenceTypeIds: "ProductImage") {
      edges {
        node {
          ... on Asset {
            primaryImage {
              title
              thumbnail: contentUrl(pipelineStepId: "plain-thumbnail")
              url: contentUrl(pipelineStepId: "web-proof-xlarge")
            }
          }
        }
      }
    }
  }
`;
