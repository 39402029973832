import { orderBy } from "lodash";

import { SectionStoreBase } from "./SectionStoreBase";
import { AttributeGroupMember, CarouselMember } from "./types";
import { AttributeGroup, Image } from "../Product/types";

interface SingleSectionProps {
  attributeGroups: AttributeGroup[];
  name: string;
  order: number;
}

class SingleSectionStore extends SectionStoreBase {
  constructor(props: SingleSectionProps) {
    super();

    const { name, attributeGroups, order } = props;

    const members = attributeGroups
      ? attributeGroups.map(member => {
        return new AttributeGroupMember(member);
      })
      : [];

    const orderedMembers = orderBy(members, this.orderMembers, ["asc"]);
    this.order = order;
    this.name = name;
    this.members = orderedMembers;
  }

  createCarousel(primaryImage: Image | undefined, productImages: Image[]) {
    if (primaryImage) {
      productImages = productImages.filter(image => image.title !== primaryImage.title);
    }

    const allImages: Image[] = primaryImage ? [primaryImage, ...productImages] : [...productImages];

    const carouselMember = new CarouselMember(allImages);
    this.addMemberAsAFirstOne(carouselMember);
  }
}

export { SingleSectionStore };
