import { Moment } from "moment";
import moment = require("moment");
import { ApprovalStates } from "../../Product/types";

export interface ApolloResponse {
  loading: boolean;
  networkStatus: number;
}

export interface FetchResult {}

export type GetNode = (stepId: string, nodeType: string | undefined) => Promise<GetNodeResponse>;

export interface GetNodeResponse extends ApolloResponse {
  data: NodeDataTO;
}

export interface NodeDataTO {
  node: NodeTO;
}

export interface NodeTO {
  stepId: string;
  title: string;
  path: string;
  approvalState: ApprovalStates;
  currentRevision: string;
  lastUpdateDate: string;
  approvalDate: string;
}

export class Node {
  stepId: string;
  title: string;
  path: string;
  approvalState: ApprovalStates;
  currentRevision: string;
  lastUpdateDate?: Moment;
  approvalDate?: Moment;

  constructor(nodeTO: NodeTO) {
    const { stepId, title, path, currentRevision, approvalState } = nodeTO;
    this.stepId = stepId;
    this.title = title;
    this.path = path;
    this.currentRevision = currentRevision;
    this.approvalState = approvalState;
    this.lastUpdateDate = nodeTO.lastUpdateDate ? moment(nodeTO.lastUpdateDate) : undefined;
    this.approvalDate = nodeTO.approvalDate ? moment(nodeTO.approvalDate) : undefined;
  }
}

export interface Reason {
  message: string;
}

export interface SufficiencyResponse {
  sufficiencies: {
    sufficiency: {
      allSufficiencies: SufficiencyResult[];
    };
  };
}

export interface Sufficiencies {
  stepId: string;
  sufficiency: {
    allSufficiencies: SufficiencyResult[];
  };
}

export interface SufficiencyResult {
  severityLevel: SeverityLevel;
  description?: string;
  evaluationDetails: EvaluationDetails;
}

export interface EvaluationDetails extends WithSeverityLevel {
  metricId: string;
  metricName: string;
  metricType?: string;
  calculationDate: string;
  score: string;
  showAsBoolean: boolean;
  messageHolders?: MessageHolders[];
  evaluationDetails?: EvaluationDetails[];
}

export interface MessageHolders {
  dataType: {
    stepId: string;
    title: string;
  };
  messages: Message[];
}

export interface Message extends WithSeverityLevel {
  message: string;
}

export interface WithSeverityLevel {
  severityLevel: SeverityLevel;
}

export enum SeverityLevel {
  Success = "SUCCESS",
  Warning = "WARNING",
  Error = "ERROR"
}
