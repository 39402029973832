import { TimGraphApollo } from "tim-bridge";
import { ApolloQueryResult } from "apollo-client/core/types";
import { ProductTO } from "../../Product/types";
import { AppConfig } from "../../types";
import { fetchProductNew, createBatchQueries, BatchQueriesResponse } from "./queries/optimizedQueries";

interface ViewerClientProps {
  graphqlClient: TimGraphApollo;
  config: AppConfig;
}

export type batchAttributeGroupRequest = (id: string[]) => Promise<ApolloQueryResult<BatchQueriesResponse>>;

export interface IViewerClient {
  fetchProduct: () => Promise<ApolloQueryResult<ProductResponse>>;
  batchAttributeGroup: batchAttributeGroupRequest;
}

export type ProductResponse = {
  product: ProductTO;
};

class ViewerClient implements IViewerClient {
  constructor(private props: ViewerClientProps) { }

  fetchProduct = () => {
    const { graphqlClient: client, config } = this.props;

    return client.query({
      query: fetchProductNew(), // this query will be changed to the optimized version
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
        nodeType: config.nodeType,
        stepId: config.stepId
      },
      fetchPolicy: "network-only"
    });
  };

  batchAttributeGroup = (id: string[]) => {
    const { graphqlClient: client, config } = this.props;
    return client.query({
      query: createBatchQueries(id),
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
      }
    });
  }
}

export { ViewerClient };
