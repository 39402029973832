export const webkitScrollbar = {
  "&::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "16px"
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff"
  },
  "&::-webkit-scrollbar-track:hover": {
    backgroundColor: "#f4f4f4"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
    border: "5px solid #fff"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a0a0a5",
    border: "4px solid #f4f4f4"
  },
  "&::-webkit-scrollbar-button": {
    display: "none"
  }
};
