import * as React from "react";
import {Typography, WithStiboStyles, withStiboStyles} from "tim-ui";
import { observer } from "mobx-react";

import { styles, TClasses } from "./Section.styles";
import { ElementNames, generateElementId } from "../../../../common/dataUtils";

interface SectionProps {
  name: string;
}

@observer
export class Section extends React.Component<SectionProps & WithStiboStyles<TClasses>> {
  render() {
    const { classes, name } = this.props;
    const elementId: string = generateElementId(ElementNames.section, name);

    return (
      <section className={classes.container} id={elementId}>
          <header>
            <Typography variant="subheading" className={classes.header}>{name}</Typography>
          </header>
        <div className={classes.wrapper}>{this.props.children}</div>
      </section>
    );
  }
}

export default withStiboStyles(styles)(Section);
